import { post, put } from "./base";

const API_SERVICE_QUERY = "/api/Service/V1/query";
const API_SERVICE = "/api/sivi_data/V1";

export default {
  getAll: (currentPage, filter, search) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_data_select_mensajes",
          type: "string"
        },
        {
          name: "@page",
          value: currentPage,
          type: "int"
        },
        {
          name: "@filtros_AND",
          value: "activo",
          type: "string"
        },
        {
          name: "@buscar_AND",
          value: `%${filter}%`,
          type: "string"
        },
        {
          name: "@filtros_OR",
          value: "denominacion|descripcion",
          type: "string"
        },
        {
          name: "@buscar_OR",
          value: `%${search}%|%${search}%`,
          type: "string"
        }
      ]
    };

    console.log(request);
    return post(API_SERVICE_QUERY, request);
  },

  addOne: (payload) => {
    const request = {
      _type: 2,
      _table: "sms_mensaje",
      _petitions: [
        {
          name: "denominacion",
          value: payload.denominacion,
          type: "string"
        },
        {
          name: "descripcion",
          value: payload.descripcion,
          type: "string"
        },
        {
          name: "abreviatura",
          value: payload.abreviatura,
          type: "string"
        },
        {
          name: "activo",
          value: "1",
          type: "int"
        }
      ]
    };

    console.log(request);
    return post(API_SERVICE, request);
  },

  editOne: (payload, iddestinatario) => {
    const request = {
      _type: 3,
      _table: "sms_mensaje",
      _petitions: [
        {
          name: "denominacion",
          value: payload.denominacion,
          type: "string"
        },
        {
          name: "descripcion",
          value: payload.descripcion,
          type: "string"
        },
        {
          name: "abreviatura",
          value: payload.abreviatura,
          type: "string"
        }
      ]
    };
    return put(API_SERVICE + "/" + iddestinatario, request);
  },

  deleteOne: (iddestinatario) => {
    const request = {
      _type: 3,
      _table: "sms_mensaje",
      _petitions: [
        {
          name: "eliminado",
          value: "1",
          type: "int"
        }
      ]
    };

    return put(API_SERVICE + "/" + iddestinatario, request);
  },

  CambiarEstadoOne: (iddestinatario, activo) => {
    const request = {
      _type: 3,
      _table: "sms_mensaje",
      _petitions: [
        {
          name: "activo",
          value: activo,
          type: "int"
        }
      ]
    };

    return put(API_SERVICE + "/" + iddestinatario, request);
  }
};
